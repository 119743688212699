import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from './Components/Footer/Footer'

const MainHeader = () => {
  return (
    <div>
        <Navbar/>

        <Outlet/>

        <Footer/>
    </div>
  )
}

export default MainHeader