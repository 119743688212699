import React from "react";
// import img2 from "../../assest/services/our-project4.webp"; // Update this image path if needed
import img1 from "../../assets/website_design.gif"; // Update this image path if needed

const WebDesign = () => {
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        {/* Main Content Section */}
        <div className="md:w-2/3">
          <img
            src={img1} // Use an appropriate image for web design
            alt="Web Design"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-700 font-bold text-primary my-3">
            Web Design Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            In the digital era, having a stunning and functional website is essential for any business. Our Web Design Services focus on creating visually appealing, user-friendly websites that drive engagement and conversions. We blend creativity with technology to design websites that not only look great but also perform optimally.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our talented team of designers conducts in-depth research to understand your brand and target audience, ensuring that the website we create reflects your identity and meets your goals. We prioritize user experience (UX) and user interface (UI) design principles to craft websites that are intuitive and easy to navigate.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            We understand the importance of responsive design in today’s mobile-driven world. Our websites are fully responsive, ensuring they look and function beautifully on all devices, from desktops to smartphones. Additionally, we implement the latest web technologies to enhance performance and speed, contributing to an optimal user experience.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our Web Design Services also encompass branding, content creation, and SEO optimization. We create cohesive branding elements that resonate with your audience, and our content strategies ensure that your message is clear and impactful. Furthermore, we optimize your website for search engines, enhancing its visibility and driving organic traffic.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            With our comprehensive approach to web design, we provide ongoing support and maintenance to ensure your website remains up-to-date and performs at its best. We believe in building long-term relationships with our clients, offering assistance and updates as your business evolves.
          </p>
          <p className="text-muted-foreground font-semibold">
            Transform Your Online Presence with Our Web Design Services!
          </p>
          <p className="text-muted-foreground">Call Us: +91 1234567890</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-700 rounded-lg p-2 mb-4">Enquire Now</h3>
            <form className="flex flex-col bg-red-500 rounded-lg p-4">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business URL"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>
              <input
                type="text"
                placeholder="Enter Captcha Code"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <button
                type="submit"
                className="bg-white text-red-500  hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-700 rounded-lg p-2">
              Our Web Design Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Custom Web Design</li>
              <li className="py-2 text-lg">Responsive Design</li>
              <li className="py-2 text-lg">UI/UX Design</li>
              <li className="py-2 text-lg">E-commerce Solutions</li>
              <li className="py-2 text-lg">Content Management Systems</li>
              <li className="py-2 text-lg">Branding and Identity</li>
              <li className="py-2 text-lg">SEO Optimization</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDesign;
