import React from "react";
import img2 from "../../assets/SMO.gif"; // Update the image path
import img1 from "../../assets/SMO1.png";
const SMOAds = () => {
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        <div className="md:w-2/3">
          <img
            src={img2}
            alt="SMO Ads"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-500 font-bold text-primary my-3">
            Social Media Optimization (SMO) Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            Social Media Optimization (SMO) is a powerful digital marketing
            strategy that focuses on enhancing your brand’s visibility and
            engagement across various social media platforms. With billions of
            active users on platforms like Facebook, Instagram, Twitter,
            LinkedIn, and others, SMO provides a direct way for businesses to
            connect with their target audience, build strong online communities,
            and drive organic traffic to their websites.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our SMO services are tailored to help businesses grow their online
            presence by creating engaging, shareable content that resonates with
            their audience. We focus on optimizing your social media profiles,
            curating content that aligns with your brand's messaging, and
            implementing strategies that encourage interaction and participation
            from your followers. Whether it's creating compelling posts, using
            trending hashtags, or running interactive campaigns, we ensure your
            brand becomes an integral part of the social media conversation.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            By leveraging a combination of organic growth techniques and paid
            social media strategies, we help your business reach a broader
            audience. Our SMO services go beyond posting updates; we analyze the
            performance of each campaign, refine strategies based on data, and
            continually optimize content to ensure the highest engagement and
            conversion rates. This approach ensures that your brand remains
            relevant in an ever-changing digital landscape.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            With precise targeting options, we focus on connecting you with the
            right audience by considering key demographics such as location,
            age, gender, interests, and online behavior. This targeting ensures
            your content reaches users who are most likely to engage with your
            brand and take meaningful actions, such as visiting your website,
            signing up for newsletters, or making a purchase.
          </p>
          <img
            src={img1}
            alt="SMO Ads"
            className="w-full h-auto rounded-lg mb-4"
          />
          <p className="text-muted-foreground mb-4 text-xl">
            Our team of experts monitors your social media performance and
            continuously adjusts strategies to improve your online presence.
            From boosting your posts to increasing follower engagement, we
            ensure that your social media profiles are optimized to deliver
            maximum value. Additionally, we provide detailed reports that
            highlight your campaign’s progress, so you always have a clear
            understanding of your ROI.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            With our SMO services, you will not only increase your brand’s
            visibility but also build long-lasting relationships with your
            audience. Whether you're a small business or a large enterprise, our
            tailored SMO strategies are designed to help you achieve sustainable
            growth and establish a strong presence on social media.
          </p>
          <p className="text-muted-foreground font-semibold">
            Elevate your brand’s online presence with SMO – engage, grow, and
            succeed!
          </p>
          <p className="text-muted-foreground">Call Us: +91-9990769343</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2 mb-4">
              Enquire Now
            </h3>
            <form className="flex flex-col bg-red-500 rounded-lg p-4">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>

              <button
                type="submit"
                className="bg-white text-red-500 hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2">
              Our SMO Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Profile Optimization</li>
              <li className="py-2 text-lg">Content Creation & Curation</li>
              <li className="py-2 text-lg">Audience Engagement</li>
              <li className="py-2 text-lg">Hashtag Strategy</li>
              <li className="py-2 text-lg">Influencer Collaboration</li>
              <li className="py-2 text-lg">Performance Tracking & Reporting</li>
              <li className="py-2 text-lg">Social Media Campaigns</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMOAds;
