import React from "react";
import MainHomeSection from "../../Components/MainHomeSection/MainHomeSection";
import Service from "../../Components/Service/Service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import img1 from "../../assets/project2.png";
import img2 from "../../assets/project1.png";
import img3 from "../../assets/interior.png";
import img4 from "../../assets/Rangmanch Events.png";

const Home = () => {
  return (
    <div>
      <MainHomeSection />

      <div className="about-container mx-auto max-w-7xl p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="video-container">
            <video className="w-full h-auto rounded-lg" autoPlay loop muted>
              <source
                src={require("../../assets/about.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="content-container flex flex-col justify-center">
            <h2 className="text-3xl font-bold mb-4 underline">About Us</h2>
            <p className="text-lg mb-4">
              We are dedicated to providing top-notch digital marketing services
              that help businesses grow and thrive online. Our team of experts
              specializes in various aspects of digital marketing, including
              SEO, social media, and content creation.
            </p>
            <p className="text-lg mb-4">
              With a passion for innovation and results-driven strategies, we
              aim to elevate your brand's online presence and connect you with
              your target audience effectively.
            </p>
            <p className="text-lg mb-4">
              Our mission is to empower businesses of all sizes by leveraging
              the latest digital marketing trends and technologies. We believe
              that every brand has a unique story to tell, and our goal is to
              help you share that story with the world.
            </p>

            <a
              href="/more-info"
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 text-center rounded transition duration-300"
            >
              Know More
            </a>
          </div>
        </div>
        {/* services Components here */}
        <div className="mt-8">
          <Service />
        </div>
        <div className="mt-8">
          <div className="h-[30vh] w-full flex flex-col items-center justify-center bg-gradient-to-l from-[#f85959] to-[#ff1313] rounded-lg">
            <h4 className="w-[90%] text-3xl font-semibold leading-[45px] text-center text-white uppercase">
              SIGN UP FOR SIDCUP NEWS AND SPECIAL <br /> OFFERS STRAIGHT TO YOUR
              INBOX
            </h4>
            <button className="bg-white text-red-500 font-semibold px-4 py-2 mt-4 rounded hover:bg-white-500 hover:text-red-500 transition duration-300">
              Book Now
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-8">
            <div className="flex flex-col items-center justify-center h-40 w-full p-4 bg-gradient-to-r from-[#ff7e7e] to-[#ff1313] rounded-lg shadow-md">
              <h2 className="text-3xl font-bold">5+ Years</h2>
              <p className="text-gray-100 ">Total Experience</p>
            </div>
            <div className="flex flex-col items-center justify-center h-40 w-full p-4 bg-gradient-to-r from-[#ff7e7e] to-[#ff1313] rounded-lg shadow-md">
              <h2 className="text-3xl font-bold">50+</h2>
              <p className="text-gray-100 ">Completed Projects</p>
            </div>
            <div className="flex flex-col items-center justify-center h-40 w-full p-4 bg-gradient-to-r from-[#ff7e7e] to-[#ff1313] rounded-lg shadow-md">
              <h2 className="text-3xl font-bold">200+</h2>
              <p className="text-gray-100 ">Happy Customers</p>
            </div>
            <div className="flex flex-col items-center justify-center h-40 w-full p-4 bg-gradient-to-r from-[#ff7e7e] to-[#ff1313] rounded-lg shadow-md">
              <h2 className="text-3xl font-bold">10+</h2>
              <p className="text-gray-100 ">Running Projects</p>
            </div>
          </div>
        </div>
      </div>

      {/* Our Latest Projects Section */}
      <section id="projects" className="py-12 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold text-white">
            Our Latest Projects
          </h2>
          <p className="mt-4 text-lg text-gray-400">
            Discover the remarkable projects we've recently completed for our
            clients.
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Project 1 */}
          <div className="bg-red-500 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transform transition hover:-translate-y-2 duration-300">
            <img
              className="w-full h-56 object-cover"
              src={img2}
              alt="Project 1"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold text-gray-900">
                Flapkart E-commerce
              </h3>
              <p className="text-sm text-white">
                Full-featured E-commerce Website
              </p>
              <div className="mt-4">
                <a
                  href="https://flapkart.in"
                  className="bg-white text-red-400 px-4 py-2 rounded-lg "
                >
                  View Project
                </a>
              </div>
            </div>
          </div>

          {/* Project 2 */}
          <div className="bg-red-500 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transform transition hover:-translate-y-2 duration-300">
            <img
              className="w-full h-56 object-cover"
              src={img1}
              alt="Project 2"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold text-gray-900">
                Web Alpha Techosoft
              </h3>
              <p className="text-sm text-white">
                Corporate Website for IT Solutions
              </p>
              <div className="mt-4">
                <a
                  href="https://webalphatechnosoft.com"
                  className="bg-white text-red-400 px-4 py-2 rounded-lg "
                >
                  View Project
                </a>
              </div>
            </div>
          </div>

          {/* Project 3 */}
          <div className="bg-red-500 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transform transition hover:-translate-y-2 duration-300">
            <img
              className="w-full h-56 object-cover"
              src={img3}
              alt="Project 3"
            />
            <div className="p-6 text-center">
              <h3 className="text-xl font-semibold text-gray-900">
              Inerior Design
              </h3>
              <p className="text-sm text-white">Online Bookstore Platform</p>
              <div className="mt-4">
                <a
                  href="https://ecwastore.com"
                  className="bg-white text-red-400  px-4 py-2 rounded-lg "
                >
                  View Project
                </a>
              </div>
            </div>
          </div>

          {/* Project 4 */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transform transition hover:-translate-y-2 duration-300 un">
            <img
              className="w-full h-56 object-cover"
              src={img4}
              alt="Project 4"
            />
            <div className="p-6 text-center bg-red-500">
              <h3 className="text-xl font-semibold text-gray-900">
                Rangmanch Events
              </h3>
              <p className="text-sm text-white">
                Event Planning & Management Website
              </p>
              <div className="mt-4">
                <a
                  href="https://rangmanchevents.com"
                  className="bg-white text-red-400  px-4 py-2 rounded-lg "
                >
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials section will be here */}
      <section className="py-16 bg-white rounded-lg">
        <div className="mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-red-700 mb-8">
            What Our Clients Say
          </h2>
          <p className="text-black text-lg py-8">
            Inox Digital Solution has been instrumental in driving the online
            presence of our business. Their team has a deep understanding of
            digital marketing strategies, offering top-notch services in social
            media advertising, SEO, and web design. They continuously deliver
            outstanding results with precision and professionalism. From
            launching high-performing ad campaigns to enhancing our website,
            their commitment to excellence sets them apart. We highly recommend
            them to any business seeking a digital marketing partner.
          </p>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            loop={true}
          >
            {/* Slide 1 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://img.freepik.com/free-photo/portrait-indian-happy-businessman-using-laptop-computer-office_231208-2580.jpg"
                  alt="Aman Verma"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Inox Digital Solution helped us transform our online presence
                  with powerful Instagram and Facebook ad campaigns. Our
                  engagement has increased significantly, and we’re reaching the
                  right audience. Highly recommended!"
                </p>
                <h1 className="font-semibold text-2xl">Aman Verma</h1>
              </div>
            </SwiperSlide>

            {/* Slide 2 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://t4.ftcdn.net/jpg/06/79/50/91/360_F_679509191_FQW7KbRAaHVkCryRlomSQXOeM354SdJY.jpg"
                  alt="Pooja Mehra"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Thanks to Inox Digital Solution, our Google Ads campaigns
                  have produced excellent results. They created a custom
                  strategy that increased our leads and conversions
                  tremendously."
                </p>
                <p className="font-semibold text-2xl">Pooja Mehra</p>
              </div>
            </SwiperSlide>

            {/* Slide 3 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://r2.erweima.ai/imgcompressed/img/compressed_93d2b03b93923a35d17195ac7375e5d7.webp"
                  alt="Rajat Singh"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "We have seen impressive growth in our search engine rankings,
                  thanks to their expert SEO services. Inox Digital Solution
                  knows how to drive organic traffic and improve visibility."
                </p>
                <p className="font-semibold text-2xl">Rajat Singh</p>
              </div>
            </SwiperSlide>

            {/* Slide 4 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://media.istockphoto.com/id/1386217759/photo/portrait-of-a-confident-young-businesswoman-standing-against-an-urban-background.jpg?s=612x612&w=0&k=20&c=fAzBj4UAksz3wwAjXxKxByZMqDSuqQZSTij7jBiPtJc="
                  alt="Neha Sharma"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The social media optimization (SMO) services provided by Inox
                  Digital Solution helped us engage more effectively with our
                  audience. Their design and content ideas were spot on!"
                </p>
                <p className="font-semibold text-2xl">Neha Sharma</p>
              </div>
            </SwiperSlide>

            {/* Slide 5 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://www.shutterstock.com/image-photo/portrait-confident-young-indian-teenager-600nw-2157123233.jpg"
                  alt="Vikram Singh"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The web design service we received from Inox Digital Solution
                  exceeded our expectations. Our new site is modern,
                  user-friendly, and optimized for both desktop and mobile."
                </p>
                <p className="font-semibold text-2xl">Vikram Singh</p>
              </div>
            </SwiperSlide>

            {/* Slide 6 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://img.freepik.com/free-photo/cheerful-curly-business-girl-wearing-glasses_176420-206.jpg"
                  alt="Riya Kapoor"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The posting designs created by Inox Digital Solution are
                  visually stunning and have significantly boosted our social
                  media engagement. They really understand the aesthetics needed
                  for effective branding."
                </p>
                <p className="font-semibold text-2xl">Riya Kapoor</p>
              </div>
            </SwiperSlide>

            {/* Slide 7 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/029/771/887/non_2x/portrait-of-a-handsome-businessman-in-modern-office-asian-manager-looking-at-camera-and-smiling-confident-male-ceo-planning-and-managing-company-strategy-free-photo.jpeg"
                  alt="Amit Patel"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "With Inox Digital Solution, our social media ads on Facebook
                  and Instagram are generating high-quality leads at a lower
                  cost. Their expertise in targeting the right audience has been
                  remarkable."
                </p>
                <p className="font-semibold text-2xl">Amit Patel</p>
              </div>
            </SwiperSlide>

            {/* Slide 8 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/035/804/667/non_2x/ai-generated-korean-girl-in-a-black-blazer-and-a-white-shirt-standing-against-a-office-free-photo.jpg"
                  alt="Sanya Gupta"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Inox Digital Solution’s Google Ads campaigns have increased
                  our website traffic significantly. Their strategic approach
                  and in-depth analysis helped us get the best return on
                  investment."
                </p>
                <p className="font-semibold text-2xl">Sanya Gupta</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default Home;
