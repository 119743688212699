import React from "react";
import img2 from "../../assets/post-design.webp"; // Update this image path if needed

const PostDesign = () => {
  const checkmarkStyle = {
    color: "green",
  };
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        {/* Main Content Section */}
        <div className="md:w-2/3">
          <img
            src={img2}
            alt="Post Design"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-700 font-bold text-primary my-3">
            Post Design Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            In today's digital landscape, the importance of effective post
            design cannot be overstated. Whether it’s for social media,
            websites, or advertisements, compelling post design can
            significantly impact audience engagement. Our Post Design services
            are tailored to create visually appealing and informative posts that
            captivate your target audience.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our team of designers specializes in crafting posts that not only
            look great but also align with your brand’s messaging and
            objectives. We focus on understanding your target audience and
            tailoring the design elements—colors, typography, and imagery—to
            resonate with them effectively.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            From eye-catching graphics to well-thought-out layouts, our Post
            Design services are all about enhancing your online presence. We
            ensure that every post we create is optimized for maximum impact,
            leading to increased shares, likes, and overall engagement with your
            content.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Whether you need designs for promotional campaigns, social media
            posts, or content marketing efforts, we have you covered. Our design
            process includes collaboration and feedback loops to ensure that the
            final output meets your expectations and delivers results.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Additionally, we understand the importance of brand consistency
            across all platforms. Our designers ensure that every post maintains
            your brand's identity, which is essential for building trust and
            recognition among your audience. We utilize your brand colors,
            fonts, and logos to create a cohesive look and feel, making your
            posts easily identifiable.
          </p>
          {/* <p className="text-muted-foreground mb-4 text-xl">
            Another key aspect of our Post Design services is the integration of data-driven strategies. We analyze previous post performances to understand what resonates most with your audience. By leveraging insights from analytics, we can make informed decisions about design elements, content types, and posting schedules, ensuring optimal engagement.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            In addition to static designs, we also offer animated post designs that add a dynamic element to your content. Animations can enhance storytelling and keep your audience engaged longer. Our team can create GIFs, short video clips, or animated infographics that not only grab attention but also effectively communicate your message.
          </p> */}
          {/* table here  */}
          <div className="container my-5 bg-white text-black rounded-lg p-2 ">
            <h2 className="text-3xl py-8 font-bold mb-4 text-center text-red-700">
              Social Media Management Packages
            </h2>
            <div className="overflow-auto">
              <table className="table table-bordered">
                <thead className="bg-red-500 text-white">
                  <tr>
                    <th>Social Media Management Package</th>
                    <th>3 Month</th>
                    <th>6 Months</th>
                    <th>12 Months</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Account Set-Up Section */}
                  <tr>
                    <td colSpan="4" className="font-semibold bg-gray-200">
                      Account Set-Up
                    </td>
                  </tr>
                  <tr>
                    <td>1. Facebook</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>2. Instagram</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  {/* Content Creation & Scheduling Section */}
                  <tr>
                    <td colSpan="4" className="font-semibold bg-gray-200">
                      Content Creation & Scheduling
                    </td>
                  </tr>
                  <tr>
                    <td>8-10 Artwork or Video Reels, Post in a Month</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span> + 5-8 post
                      (Offer)
                    </td>
                  </tr>
                  <tr>
                    <td>Manage Social Media Account (SMM)</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Holiday Covers (Artwork)</td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Monthly Report</td>
                    <td></td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  {/* Facebook & Instagram Ads Section */}
                  <tr>
                    <td colSpan="4" className="font-semibold bg-gray-200">
                      Facebook and Instagram Ads
                    </td>
                  </tr>
                  <tr>
                    <td>Creating, Consulting & Management of Facebook Ads</td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Continuous Monitoring for Optimal Performance</td>
                    <td></td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Lead Generation for Enhanced Conversions</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Creative Graphic Design to Make Your Ads</td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Engaging Content Writing - Ad Text, Captions, Taglines
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Results and Reports for Ads</td>
                    <td></td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Campaign Creation (2-3 Monthly)</td>
                    <td></td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Ad Management Fee: 20% of Monthly Ad Spend</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Target Location: India</td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                    <td>
                      <span style={checkmarkStyle}>&#10004;</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Tax and Extra: 0 INR</td>
                    <td>0 INR</td>
                    <td>0 INR</td>
                    <td>0 INR</td>
                  </tr>
                  {/* Charges Section */}
                  <tr className="text-xl text-red-700 mt-4">
                    <td>
                      <strong>Total Price (INR) + GST (Pay 50% Advance)</strong>
                    </td>
                    <td>14,999/- + GST</td>
                    <td>29,999/- + GST</td>
                    <td>59,999/- + GST</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="text-muted-foreground font-semibold">
            Elevate Your Brand with Stunning Post Designs!
          </p>
          <p className="text-muted-foreground">Contact Us: +91 1234567890</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-700 rounded-lg p-2 mb-4">
              Enquire Now
            </h3>
            <form className="flex flex-col">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business URL"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>
              <input
                type="text"
                placeholder="Enter Captcha Code"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <button
                type="submit"
                className="bg-red-700 hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-700 rounded-lg p-2">
              Our Post Design Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Custom Graphics</li>
              <li className="py-2 text-lg">Social Media Posts</li>
              <li className="py-2 text-lg">Promotional Banners</li>
              <li className="py-2 text-lg">Infographics</li>
              <li className="py-2 text-lg">Branding Collateral</li>
              <li className="py-2 text-lg">Content Marketing Designs</li>
              <li className="py-2 text-lg">Layout Designs</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDesign;
