import React from "react";
// import img1 from "../../assets/ads/facebook-ad1.webp";
import img2 from "../../assets/facebook-ads.png";

const FacebookAds = () => {
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        {/* Main Content Section */}
        <div className="md:w-2/3">
          <img
            src={img2}
            alt="Facebook Ads"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-500 font-bold text-primary my-3">
            Facebook Advertising Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            Facebook Ads have revolutionized how businesses can connect with
            their audiences. With over 2.8 billion active users, Facebook offers
            a vast opportunity for companies to reach their target market
            effectively. Our Facebook advertising services are designed to help
            you capitalize on this potential by creating impactful ads that
            capture attention and drive conversions.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our service includes creating custom ad strategies that align with
            your business goals. Whether you're looking to build brand
            awareness, increase website traffic, or drive sales, we craft ads
            that resonate with your target audience. By utilizing various ad
            formats such as image ads, video ads, carousel ads, and collection
            ads, we ensure that your campaigns stand out in the crowded Facebook
            landscape.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            One of the key benefits of Facebook advertising is the ability to
            target specific demographics based on location, age, gender,
            interests, and behaviors. We leverage Facebook's powerful targeting
            tools to ensure your ads reach the right audience at the right time.
            This precise targeting helps in optimizing ad spend and delivering a
            higher return on investment (ROI).
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our team of experienced advertisers continuously monitor and
            optimize your campaigns to improve performance. From testing
            different ad creatives to adjusting bidding strategies, we ensure
            that your ads are always performing at their best. We provide
            detailed reports and insights, so you can track the progress and
            success of your campaigns in real time.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            With our Facebook advertising services, you'll not only increase
            your online visibility but also create meaningful engagements with
            your potential customers. Whether you're a small business or a large
            corporation, our tailored solutions are designed to help you achieve
            your marketing objectives.
          </p>
          <p className="text-muted-foreground font-semibold">
            Take your marketing to the next level with Facebook Ads – connect,
            engage, and convert!
          </p>
          <p className="text-muted-foreground">Call Us: +91-9990769343</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2 mb-4">
              Enquire Now
            </h3>
            <form className="flex flex-col bg-red-500 rounded-lg p-4">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>
              
              <button
                type="submit"
                className="bg-white text-red-500 hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2">
              Our Facebook Ad Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Facebook Photo Ads</li>
              <li className="py-2 text-lg">Facebook Video Ads</li>
              <li className="py-2 text-lg">Facebook Carousel Ads</li>
              <li className="py-2 text-lg">Facebook Collection Ads</li>
              <li className="py-2 text-lg">Audience Targeting</li>
              <li className="py-2 text-lg">Facebook Ad Analytics</li>
              <li className="py-2 text-lg">Ad Campaign Management</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookAds;
