import React from "react";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaRegAddressCard,
  FaRegLightbulb,
} from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import img1 from "../../assets/about1.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";

const AboutPage = () => {
  return (
    <div className=" text-white">
      {/* Hero Section */}
      <section className="hero relative bg-gray-800 p-10 h-96 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-50"
          style={{
            backgroundImage:
              "url('https://static.vecteezy.com/system/resources/previews/036/290/329/non_2x/about-us-metaphor-company-information-flat-illustration-template-business-profile-office-information-customer-support-our-team-about-company-vector.jpg')",
          }}
        ></div>

        <div className="relative z-10 text-center">
          <h1 className="text-4xl font-bold text-white animate-fadeIn">
            About Inox Digital Solution
          </h1>
        </div>
      </section>

      {/* Video Section */}
      <section className="flex flex-col md:flex-row p-10">
        {/* Left Div - Content */}
        <div className="md:w-1/2 p-5">
          <h2 className="text-3xl text-red-500 font-semibold animate-fadeIn">
            Welcome to Inox Digital Solution
          </h2>
          <p className="mt-4 text-lg animate-fadeIn">
            At Inox Digital Solution, we leverage the power of digital marketing
            to help businesses grow and thrive in the online world. Our team of
            experts is dedicated to crafting tailored marketing strategies that
            drive results.
          </p>
          <p className="mt-4 text-lg animate-fadeIn">
            At Inox Digital Solution, we leverage the power of digital marketing
            to help businesses grow and thrive in the online world. Our team of
            experts is dedicated to crafting tailored marketing strategies that
            drive results.
          </p>
          <p className="mt-4 text-lg animate-fadeIn">
            At Inox Digital Solution, we leverage the power of digital marketing
            to help businesses grow and thrive in the online world. Our team of
            experts is dedicated to crafting tailored marketing strategies that
            drive results.
          </p>
          <div className="mt-8">
            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded animate-fadeIn">
              Read More
            </button>
          </div>
        </div>

        {/* Right Div - Video */}
        <div className="video-container">
          <img src={img1} alt="" />
        </div>
      </section>

      {/* Vision Section */}
      <section className="flex flex-col md:flex-row p-10 bg-gray-100 rounded-lg">
        <div className="md:w-1/2 p-5">
          <h2 className="text-4xl font-bold animate-fadeIn text-red-800">
            Our Vision
          </h2>
          <p className="mt-4 text-lg animate-fadeIn text-gray-700">
            Our vision is to be a driving force in the digital marketing world,
            helping brands realize their full potential online.
          </p>
          <ul className="mt-4 text-lg animate-fadeIn list-disc list-inside text-gray-700 space-y-2">
            <li>Lead the industry with innovation-driven strategies.</li>
            <li>
              Transform digital marketing through creativity and technology.
            </li>
            <li>Establish lasting partnerships with global brands.</li>
            <li>
              Empower businesses of all sizes to grow through digital
              transformation.
            </li>
            <li>
              Stay ahead of the curve by embracing cutting-edge technologies and
              marketing trends.
            </li>
          </ul>
        </div>
        {/* Mission Section */}
        <div className="md:w-1/2 p-5">
          <h2 className="text-4xl font-bold animate-fadeIn text-red-800">
            Our Mission
          </h2>
          <p className="mt-4 text-lg animate-fadeIn text-gray-700">
            Our mission is to deliver measurable results that drive success for
            our clients through powerful digital strategies.
          </p>
          <ul className="mt-4 text-lg animate-fadeIn list-disc list-inside text-gray-700 space-y-2">
            <li>
              Provide personalized and data-driven marketing solutions tailored
              to each client.
            </li>
            <li>
              Maximize brand visibility and engagement across all digital
              platforms.
            </li>
            <li>
              Drive sustainable growth and long-term success for businesses.
            </li>
            <li>
              Leverage analytics and insights to refine strategies and achieve
              measurable outcomes.
            </li>
            <li>
              Offer a seamless customer experience and unmatched support
              throughout every campaign.
            </li>
          </ul>
        </div>
      </section>

      {/* Services Overview */}
      <section className="services p-10 ">
        <h2 className="text-4xl font-bold text-center animate-fadeIn">
          🌟Our Services🌟
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
          {/* SEO Service */}
          <div className="service-card bg-gray-800 text-center p-8 rounded-lg shadow-lg animate-fadeIn">
            <div className="icon bg-blue-600 text-white w-16 h-16 flex items-center justify-center rounded-full mx-auto mb-4">
              <FaRegLightbulb className="text-3xl" />
            </div>
            <h3 className="text-2xl font-semibold text-white">SEO</h3>
            <p className="mt-4 text-gray-300">
              Optimize your website to rank higher in search engine results. We
              utilize advanced techniques to ensure your site is fully
              optimized, driving organic traffic and increasing visibility.
            </p>
          </div>

          {/* Content Marketing Service */}
          <div className="service-card bg-gray-800 text-center p-8 rounded-lg shadow-lg animate-fadeIn">
            <div className="icon bg-green-600 text-white w-16 h-16 flex items-center justify-center rounded-full mx-auto mb-4">
              <FaRegAddressCard className="text-3xl" />
            </div>
            <h3 className="text-2xl font-semibold text-white">
              Content Marketing
            </h3>
            <p className="mt-4 text-gray-300">
              Create and distribute valuable content to attract and engage your
              audience. Our content marketing strategies are designed to build
              trust and establish your brand as an industry leader.
            </p>
          </div>

          {/* Social Media Management Service */}
          <div className="service-card bg-gray-800 text-center p-8 rounded-lg shadow-lg animate-fadeIn">
            <div className="icon bg-red-600 text-white w-16 h-16 flex items-center justify-center rounded-full mx-auto mb-4">
              <AiOutlineTeam className="text-3xl" />
            </div>
            <h3 className="text-2xl font-semibold text-white">
              Social Media Management
            </h3>
            <p className="mt-4 text-gray-300">
              Build and manage your brand's presence across social media
              platforms. We provide end-to-end social media solutions to grow
              your audience, increase engagement, and drive conversions.
            </p>
          </div>
        </div>
      </section>

      

      {/* Client Testimonials */}
      <section className="py-16 bg-white">
        <div className="mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-red-700 mb-8">
            What Our Clients Say
          </h2>
          <p className="text-black text-lg py-8">
            Inox Digital Solution has been instrumental in driving the online
            presence of our business. Their team has a deep understanding of
            digital marketing strategies, offering top-notch services in social
            media advertising, SEO, and web design. They continuously deliver
            outstanding results with precision and professionalism. From
            launching high-performing ad campaigns to enhancing our website,
            their commitment to excellence sets them apart. We highly recommend
            them to any business seeking a digital marketing partner.
          </p>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            loop={true}
          >
            {/* Slide 1 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://img.freepik.com/free-photo/portrait-indian-happy-businessman-using-laptop-computer-office_231208-2580.jpg"
                  alt="Aman Verma"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Inox Digital Solution helped us transform our online presence
                  with powerful Instagram and Facebook ad campaigns. Our
                  engagement has increased significantly, and we’re reaching the
                  right audience. Highly recommended!"
                </p>
                <h1 className="font-semibold text-2xl">Aman Verma</h1>
              </div>
            </SwiperSlide>

            {/* Slide 2 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://t4.ftcdn.net/jpg/06/79/50/91/360_F_679509191_FQW7KbRAaHVkCryRlomSQXOeM354SdJY.jpg"
                  alt="Pooja Mehra"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Thanks to Inox Digital Solution, our Google Ads campaigns
                  have produced excellent results. They created a custom
                  strategy that increased our leads and conversions
                  tremendously."
                </p>
                <p className="font-semibold text-2xl">Pooja Mehra</p>
              </div>
            </SwiperSlide>

            {/* Slide 3 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://r2.erweima.ai/imgcompressed/img/compressed_93d2b03b93923a35d17195ac7375e5d7.webp"
                  alt="Rajat Singh"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "We have seen impressive growth in our search engine rankings,
                  thanks to their expert SEO services. Inox Digital Solution
                  knows how to drive organic traffic and improve visibility."
                </p>
                <p className="font-semibold text-2xl">Rajat Singh</p>
              </div>
            </SwiperSlide>

            {/* Slide 4 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://media.istockphoto.com/id/1386217759/photo/portrait-of-a-confident-young-businesswoman-standing-against-an-urban-background.jpg?s=612x612&w=0&k=20&c=fAzBj4UAksz3wwAjXxKxByZMqDSuqQZSTij7jBiPtJc="
                  alt="Neha Sharma"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The social media optimization (SMO) services provided by Inox
                  Digital Solution helped us engage more effectively with our
                  audience. Their design and content ideas were spot on!"
                </p>
                <p className="font-semibold text-2xl">Neha Sharma</p>
              </div>
            </SwiperSlide>

            {/* Slide 5 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://www.shutterstock.com/image-photo/portrait-confident-young-indian-teenager-600nw-2157123233.jpg"
                  alt="Vikram Singh"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The web design service we received from Inox Digital Solution
                  exceeded our expectations. Our new site is modern,
                  user-friendly, and optimized for both desktop and mobile."
                </p>
                <p className="font-semibold text-2xl">Vikram Singh</p>
              </div>
            </SwiperSlide>

            {/* Slide 6 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://img.freepik.com/free-photo/cheerful-curly-business-girl-wearing-glasses_176420-206.jpg"
                  alt="Riya Kapoor"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "The posting designs created by Inox Digital Solution are
                  visually stunning and have significantly boosted our social
                  media engagement. They really understand the aesthetics needed
                  for effective branding."
                </p>
                <p className="font-semibold text-2xl">Riya Kapoor</p>
              </div>
            </SwiperSlide>

            {/* Slide 7 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/029/771/887/non_2x/portrait-of-a-handsome-businessman-in-modern-office-asian-manager-looking-at-camera-and-smiling-confident-male-ceo-planning-and-managing-company-strategy-free-photo.jpeg"
                  alt="Amit Patel"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "With Inox Digital Solution, our social media ads on Facebook
                  and Instagram are generating high-quality leads at a lower
                  cost. Their expertise in targeting the right audience has been
                  remarkable."
                </p>
                <p className="font-semibold text-2xl">Amit Patel</p>
              </div>
            </SwiperSlide>

            {/* Slide 8 */}
            <SwiperSlide>
              <div className="bg-red-700 p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/2 mx-auto text-center">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/035/804/667/non_2x/ai-generated-korean-girl-in-a-black-blazer-and-a-white-shirt-standing-against-a-office-free-photo.jpg"
                  alt="Sanya Gupta"
                  className="mx-auto mb-4 rounded-full w-24 h-24 object-cover"
                />
                <p className="italic mb-4">
                  "Inox Digital Solution’s Google Ads campaigns have increased
                  our website traffic significantly. Their strategic approach
                  and in-depth analysis helped us get the best return on
                  investment."
                </p>
                <p className="font-semibold text-2xl">Sanya Gupta</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="why-choose-us  p-10 shadow-lg rounded-lg">
        <h2 className="text-4xl font-bold text-red-600 text-center mb-6 animate-fadeIn">
          Why Choose Us?
        </h2>
        <ul className="mt-4 list-disc list-inside space-y-4">
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Proven Track Record:</strong> With years of experience, we
              have successfully delivered results for numerous clients across
              various industries.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Tailored Solutions:</strong> We develop customized
              marketing strategies that align with your specific goals, ensuring
              optimal performance.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Expert Team:</strong> Our team consists of industry
              professionals with diverse skill sets and a wealth of knowledge.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Innovative Strategies:</strong> We utilize the latest
              trends and technologies to keep your brand relevant and
              competitive.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Comprehensive Services:</strong> From SEO to web design,
              we offer a full suite of digital marketing services.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Transparent Reporting:</strong> We provide regular reports
              outlining campaign performance and metrics.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Client-Centric Approach:</strong> Your success is our
              priority. We build long-lasting relationships with our clients.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Focus on Results:</strong> Our data-driven methodologies
              help us optimize your campaigns for maximum impact.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Cost-Effective Solutions:</strong> We provide quality
              marketing solutions designed to fit your budget.
            </div>
          </li>
          <li className="flex items-start">
            <span className="text-red-600 text-2xl mr-2">✔️</span>
            <div>
              <strong>Community and Sustainability:</strong> We promote
              sustainable practices within the digital marketing industry.
            </div>
          </li>
        </ul>
      </section>

      {/* Contact Information */}
      <section className="contact bg-gray-100 p-10 rounded-lg shadow-lg">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-red-600 mb-6">Contact Us</h2>
          <p className="text-lg text-gray-700 mb-8">
            Interested in working with us? Feel free to get in touch!
          </p>

          <div className="flex flex-col md:flex-row justify-center items-center mb-8">
            <div className="flex items-center mb-4 md:mb-0 md:mr-8">
              <FaEnvelope className="text-red-600 text-2xl mr-3" />
              <p className="text-lg">
                <a
                  href="mailto:info@inoxdigitalsolution.com"
                  className="text-gray-700 hover:text-red-600 transition"
                >
                  info@inoxdigitalsolution.com
                </a>
              </p>
            </div>

            <div className="flex items-center">
              <FaPhoneAlt className="text-red-600 text-2xl mr-3" />
              <p className="text-lg">
                <a
                  href="tel:+1234567890"
                  className="text-gray-700 hover:text-red-600 transition"
                >
                  (123) 456-7890
                </a>
              </p>
            </div>
          </div>

          <form className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
            <div className="mb-4">
              <label
                className="block text-left text-gray-700 font-semibold mb-2"
                htmlFor="name"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Enter your full name"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-left text-gray-700 font-semibold mb-2"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Enter your email"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-left text-gray-700 font-semibold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="5"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Type your message"
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-red-600 text-white font-semibold p-3 rounded-lg hover:bg-red-700 transition"
            >
              Send Message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
