import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import "./App.css";
import MainHeader from "./MainHeader";
import About from "./Pages/About/About";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PageNotFound from "./Pages/PageNotFount/PageNotFount";
import InstagramAds from "./Components/ServicePage/InstagramAds";
import FacebookAds from "./Components/ServicePage/FacebookAds";
import GoogleAds from "./Components/ServicePage/GoogleAds";
import SMOAds from "./Components/ServicePage/SMOAds";
import SEOAds from "./Components/ServicePage/SEOAds";
import PostDesign from "./Components/ServicePage/PostDesign";
import WebDesign from "./Components/ServicePage/WebDesign";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainHeader />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/services/instagram-ads" element={<InstagramAds />} />
            <Route path="/services/facebook-ads" element={<FacebookAds />} />
            <Route path="/services/google-ads" element={<GoogleAds />} />
            <Route path="/services/smo" element={<SMOAds />} />
            <Route path="/services/seo" element={<SEOAds />} />
            
            <Route path="/services/posting-design" element={<PostDesign />} />
            <Route path="/services/website-design" element={<WebDesign />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
