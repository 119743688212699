import React from "react";
// import img1 from "../../assets/ads/google-ad1.webp";
import img2 from "../../assets/google-ads.gif";

const GoogleAds = () => {
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        {/* Main Content Section */}
        <div className="md:w-2/3">
          <img
            src={img2}
            alt="Google Ads"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-500 font-bold text-primary my-3">
            Google Advertising Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            Google Ads is one of the most powerful online advertising platforms available today, enabling businesses to reach potential customers at the exact moment they are searching for your products or services. With Google Ads, you can place your business front and center on the world’s largest search engine, ensuring your brand gets the visibility it deserves.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our Google Ads services focus on creating highly targeted campaigns that are tailored to your business goals. Whether your objective is to drive traffic to your website, increase brand awareness, or generate sales and leads, we develop a customized strategy that maximizes your return on investment. With extensive experience in keyword research, ad copywriting, and bid management, our team ensures your ads are seen by the right people at the right time.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            One of the key benefits of Google Ads is the ability to target users based on their search intent. Our approach starts with understanding your audience and their search behavior. We then use this insight to create campaigns that connect your business with users who are actively looking for what you offer. With precise keyword targeting, we ensure your ads appear for the most relevant search queries.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Google Ads offers various ad formats to help you achieve your marketing goals. From text-based search ads that appear at the top of Google’s search results to visually engaging display ads on the Google Display Network, our team will guide you in choosing the best format for your business. We also create and optimize shopping ads for e-commerce businesses, ensuring your products are showcased to potential buyers when they are ready to make a purchase.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our Google Ads specialists are skilled in remarketing strategies, which allow you to reconnect with users who have previously interacted with your website but didn’t complete a desired action. By displaying targeted ads across the web, we help bring these potential customers back, increasing your chances of conversion.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Managing a successful Google Ads campaign requires continuous monitoring and optimization. Our team uses advanced analytics to track the performance of your ads in real-time, making adjustments to bids, targeting, and ad copy to ensure the best results. We provide detailed reports on how your campaigns are performing and offer insights on areas of improvement to maximize the effectiveness of your ads.
          </p>
          <p className="text-muted-foreground font-semibold">
            Ready to take your business to the next level with Google Ads? Let our team of experts craft a custom campaign that delivers measurable results and grows your online presence.
          </p>
          <p className="text-muted-foreground">Call Us: +91-9990769343</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2 mb-4">
              Enquire Now
            </h3>
            <form className="flex flex-col bg-red-500 rounded-lg p-4">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>
              
              <button
                type="submit"
                className="bg-white text-red-500 hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2">
              Our Google Ad Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Google Search Ads</li>
              <li className="py-2 text-lg">Google Display Ads</li>
              <li className="py-2 text-lg">YouTube Video Ads</li>
              <li className="py-2 text-lg">Google Shopping Ads</li>
              <li className="py-2 text-lg">Remarketing Campaigns</li>
              <li className="py-2 text-lg">Keyword Research & Targeting</li>
              <li className="py-2 text-lg">Bid Management</li>
              <li className="py-2 text-lg">Detailed Analytics & Reporting</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleAds;
