import React from "react";
// import img1 from "../../assets/ads/instagram-ad1.webp";
import img2 from "../../assets/instagram-ads.png";

const InstagramAds = () => {
  return (
    <>
      <div></div>
      <div className="flex flex-col md:flex-row bg-background p-6">
        {/* Main Content Section */}
        <div className="md:w-2/3">
          <img
            src={img2}
            alt="Instagram Ads"
            className="w-full h-auto rounded-lg mb-4"
          />
          <h2 className="text-4xl text-red-500 font-bold text-primary my-6">
            Instagram Advertising Services
          </h2>
          <p className="text-muted-foreground mb-4 text-xl">
            Instagram ads are a powerful tool to reach your target audience. With over a billion users, Instagram allows businesses to engage with their customers visually through creative ads. 
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Our Instagram advertising services help you build brand awareness, increase engagement, and drive traffic to your website. Whether you want to promote products, services, or events, we create visually appealing and highly targeted ads.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            From photo ads to carousel ads, we craft campaigns that resonate with your audience. Our expert team works to optimize ad performance and ensure maximum ROI from your advertising budget.
          </p>
          <p className="text-muted-foreground mb-4 text-xl">
            Leveraging advanced targeting options, we ensure your ads reach the right people at the right time, driving sales and increasing brand loyalty.
          </p>
          <p className="text-muted-foreground font-semibold">
            Unlock the power of Instagram Ads with us!
          </p>
          <p className="text-muted-foreground">Call Us: +91 9876543210</p>
        </div>

        {/* Enquiry Form and Services Section */}
        <div className="md:w-1/3 bg-card p-4 rounded-lg shadow-md mt-4 md:mt-0">
          {/* Enquiry Form */}
          <div>
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2 mb-4">
              Enquire Now
            </h3>
            <form className="flex flex-col bg-red-500 rounded-lg p-4">
              <input
                type="text"
                placeholder="Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="tel"
                placeholder="Mobile No."
                className="border border-border rounded p-2 mb-2"
                required
              />
              <input
                type="text"
                placeholder="Business Name"
                className="border border-border rounded p-2 mb-2"
                required
              />
              <textarea
                placeholder="Your Enquiry"
                className="border border-border rounded p-2 mb-2"
                required
              ></textarea>
              {/* <input
                type="text"
                placeholder="Enter Captcha Code"
                className="border border-border rounded p-2 mb-2"
                required
              /> */}
              <button
                type="submit"
                className="bg-white text-red-500 hover:bg-secondary/80 rounded p-2"
              >
                SUBMIT
              </button>
            </form>
          </div>

          {/* Services List */}
          <div className="mt-4">
            <h3 className="text-lg font-bold bg-red-500 rounded-lg p-2">
              Our Instagram Ad Services
            </h3>
            <ul className="list-disc list-inside text-muted-foreground">
              <li className="py-2 text-lg">Instagram Photo Ads</li>
              <li className="py-2 text-lg">Instagram Video Ads</li>
              <li className="py-2 text-lg">Instagram Carousel Ads</li>
              <li className="py-2 text-lg">Instagram Stories Ads</li>
              <li className="py-2 text-lg">Instagram Sponsored Content</li>
              <li className="py-2 text-lg">Audience Targeting</li>
              <li className="py-2 text-lg">Ad Performance Analytics</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstagramAds;
