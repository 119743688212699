import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const PageNotFound = () => {
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleGoHome = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <div
      className="flex items-center justify-center h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url('/assets/404.png')",
      }}
    >
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <h1 className="text-5xl font-bold text-red-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-red-600 mb-2">Page Not Found</h2>
        <p className="text-gray-600 mb-6">
          Sorry, the page you are looking for does not exist.
        </p>
        <button
          onClick={handleGoHome}
          className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
