import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Navbar.css'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            {/* Logo */}
            <div className="flex-shrink-0 ">
              <img className="h-14 w-22" src="/inox-logo.png" alt="Logo" />
            </div>
            <div className="hidden md:block">
              <div className="ml-14 flex items-baseline space-x-9">
                <Link
                  to="/"
                  className="text-gray-300  hover:bg-gray-700 hover:text-white px-3 py-1 rounded-md text-lg font-medium"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 rounded-md text-lg font-medium"
                >
                  About
                </Link>

                {/* Services with Submenu */}
                <div className="relative group">
                  <button className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 rounded-md text-lg font-medium">
                    Services
                  </button>
                  <div className="absolute left-0 hidden group-hover:block bg-gray-700 text-white mt-0 py-2 rounded-md shadow-lg z-10">
                    <Link
                      to="/services/instagram-ads"
                      className="min-w-80 block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      Instagram Ads
                    </Link>
                    <Link
                      to="/services/facebook-ads"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      Facebook Ads
                    </Link>
                    <Link
                      to="/services/google-ads"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      Google Ads
                    </Link>
                    <Link
                      to="/services/smo"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      SMO
                    </Link>
                    <Link
                      to="/services/seo"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      SEO
                    </Link>
                    <Link
                      to="/services/posting-design"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      Posting Design
                    </Link>
                    <Link
                      to="/services/website-design"
                      className="block px-4 py-1 text-lg hover:bg-red-500"
                    >
                      Website Design
                    </Link>
                  </div>
                </div>

                <Link
                  to="/contact"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-1 rounded-md text-lg font-medium"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>

          {/* Book Now Button */}
          <div className="hidden md:flex items-center">
          <a
          href="/contact"
          className="navbar-custom-btn bg-transparent border border-white/80 text-white/80 hover:bg-[#ea2d2d] hover:border-[#e93336] hover:text-white font-semibold py-2 px-4 rounded transition duration-500 ease-in-out"
        >
          Contact Now
        </a>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              About
            </Link>

            {/* Services Submenu */}
            <div>
              <button className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                Services
              </button>
              <div className="ml-4">
                <Link
                  to="/services/instagram-ads"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Instagram Ads
                </Link>
                <Link
                  to="/services/facebook-ads"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Facebook Ads
                </Link>
                <Link
                  to="/services/google-ads"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Google Ads
                </Link>
                <Link
                  to="/services/smo"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  SMO
                </Link>
                <Link
                  to="/services/seo"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  SEO
                </Link>
                <Link
                  to="/services/posting-design"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Posting Design
                </Link>
                <Link
                  to="/services/website-design"
                  className="text-gray-300 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Website Design
                </Link>
              </div>
            </div>

            <Link
              to="/contact"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact Us
            </Link>
          </div>

          {/* Book Now Button for Mobile */}
          <div className="mt-3 px-2 py-4">
            <a
              href="tel:+1234567890"
              className="bg-red-500 text-white block text-center px-4 py-2 rounded-md text-base font-medium"
            >
              Book Now
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
