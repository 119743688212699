import { useState } from "react";
import emailjs from "emailjs-com";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    service: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_dkzqo4o",
        "template_anvo10l",
        formData,
        "RvSbKprm8aknvAEzF"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage(
            "Thank you! Your message has been sent successfully."
          );
          setFormData({
            name: "",
            companyName: "",
            email: "",
            service: "",
            message: "",
          });
          setErrorMessage("");
        },
        (err) => {
          console.error("FAILED...", err);
          setSuccessMessage("");
          setErrorMessage(
            "Failed to send the message. Please try again later."
          );
        }
      );
  };

  return (
    <>
      <section className="hero relative bg-gray-800 p-10 h-96 flex items-center justify-center">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-80"
          style={{
            backgroundImage:
              "url('https://www.vhv.rs/dpng/d/458-4589862_030onnvptu6j2izibkbi-28-contact-us-contact-us-hd-png.png')",
          }}
        ></div>
      </section>
      <section className="contact-us text-white py-16 px-5 md:px-20">
        <h2 className="text-4xl font-bold text-center mb-10 underline">
          Contact Us
        </h2>

        {/* Contact Information */}
        <div className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-8 mb-10">
          {/* Phone Section */}
          <div className="flex flex-col">
            <a
              href="tel:+919990769343"
              className="flex items-center bg-red-600 text-white p-5 rounded-lg shadow-lg hover:bg-red-700 transition duration-300 mb-2"
            >
              <FaPhoneAlt className="text-2xl mr-3" />
              <p className="text-lg">+91-9990769343</p>
            </a>
            <a
              href="https://wa.me/917065063482"
              className="flex items-center bg-red-600 text-white p-5 rounded-lg shadow-lg hover:bg-red-700 transition duration-300"
            >
              <FaSquareWhatsapp className="text-2xl mr-3" />
              <p className="text-lg">+91-7065063482</p>
            </a>
          </div>

          {/* Email Section */}
          <a
            href="mailto:inoxdigitalsolution@gmail.com"
            className="flex items-center bg-red-600 text-white p-5 rounded-lg shadow-lg hover:bg-red-700 transition duration-300"
          >
            <FaEnvelope className="text-2xl mr-3" />
            <p className="text-lg">inoxdigitalsolution@gmail.com</p>
          </a>

          {/* Address Section */}
          <div className="flex items-center bg-red-600 text-white p-5 rounded-lg shadow-lg hover:bg-red-700 transition duration-300">
            <FaMapMarkerAlt className="text-2xl mr-3" />
            <p className="text-lg">B-74 Sector-2 Noida 201301</p>
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-white text-red-600 rounded-lg shadow-lg p-8 max-w-3xl mx-auto">
          <h3 className="text-2xl font-semibold mb-6 text-center">
            Get in Touch
          </h3>
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-6">
              <label htmlFor="name" className="block text-lg font-medium mb-2">
                Your Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                id="name"
                className="w-full p-3 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                placeholder="Enter your name"
                required
              />
            </div>

            {/* Company Name */}
            <div className="mb-6">
              <label
                htmlFor="companyname"
                className="block text-lg font-medium mb-2"
              >
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                id="companyname"
                className="w-full p-3 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                placeholder="Enter your Company Name"
                required
              />
            </div>

            {/* Email */}
            <div className="mb-6">
              <label htmlFor="email" className="block text-lg font-medium mb-2">
                Your Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                id="email"
                className="w-full p-3 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Subject */}
            <div className="mb-6">
              <label
                htmlFor="service"
                className="block text-lg font-medium mb-2"
              >
                What Service Do You Need
              </label>
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                id="service"
                className="w-full p-3 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                required
              >
                <option value="">Select a service</option>
                <option value="seo">Search Engine Optimization (SEO)</option>
                <option value="sem">Search Engine Marketing (SEM)</option>
                <option value="smm">Social Media Marketing (SMM)</option>
                <option value="google-ads">Google Ads</option>
                <option value="content">Content Marketing</option>
                <option value="email">Email Marketing</option>
                <option value="ppc">Pay-Per-Click Advertising (PPC)</option>
                <option value="affiliates">Affiliate Marketing</option>
                <option value="influencer">Influencer Marketing</option>
                <option value="analytics">Web Analytics</option>
                <option value="web-design">Web Design and Development</option>
                <option value="ecommerce">E-commerce Solutions</option>
                <option value="mobile">Mobile Marketing</option>
              </select>
            </div>

            {/* Message */}
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block text-lg font-medium mb-2"
              >
                Your Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                id="message"
                className="w-full p-3 h-40 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
                placeholder="Enter your message"
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-red-600 text-white py-3 px-8 rounded-md hover:bg-red-700 transition duration-300"
              >
                Send Message
              </button>
            </div>
          </form>

          {/* Success Message */}
          {successMessage && (
            <div className="mt-6 text-center text-green-600">
              {successMessage}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ContactUs;
